import React, {useEffect} from 'react';
//import anime from "animejs";

const LogoUnicus = (props) => {
    // function animation() {
    //     anime({
    //         targets: ".logo",
    //         direction: "alternate",
    //         strokeDashoffset: [anime.setDashoffset, 0],
    //         // easing: "cubicBezier(.5, .05, 1, .3)",
    //         easing: "cubicBezier(1, .05, 1, .3)",
    //         loop: false,
    //         duration: 1000,
    //         delay: function (el, i) {
    //             return i * 250;
    //         }
    //     });
    // }

    // useEffect(() => {
    //     animation();
    // }, [])

    return (
        <>

<div className="wrap-logo" >
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
         width="100px" height="20px" viewBox="0 0 450.000000 104.000000"
         preserveAspectRatio="xMidYMid meet">

        <g transform="translate(0.000000,104.000000) scale(0.100000,-0.100000)"
           fill="#000000" stroke="none">
            <path fill={props.contourLogo} stroke={props.contourLogo} d="M2123 1000 c-97 -13 -179 -37 -278 -81 -135 -60 -210 -102 -213 -121
-5 -26 20 -22 81 11 89 50 233 110 319 132 105 27 255 29 348 5 239 -62 390
-229 339 -375 -12 -34 -27 -38 -77 -20 -20 7 -34 21 -41 40 -6 17 -39 58 -73
93 -52 52 -77 68 -143 93 -188 72 -360 71 -656 -6 -231 -60 -350 -75 -604 -75
-217 0 -243 2 -470 38 -302 48 -445 53 -517 18 -105 -52 -119 -173 -29 -240
77 -56 197 -74 405 -61 258 17 579 49 706 71 123 21 337 30 372 16 15 -6 13
-13 -13 -62 -80 -147 -28 -281 142 -367 114 -57 194 -72 379 -73 153 0 171 2
247 27 169 56 272 159 298 297 4 20 24 54 48 81 l42 47 70 -10 c115 -16 427
-3 615 27 409 64 557 78 750 73 141 -3 160 -6 205 -28 40 -20 51 -31 53 -53 8
-69 -103 -122 -333 -158 -119 -19 -483 -12 -850 15 -469 34 -542 37 -555 21
-20 -24 14 -32 165 -38 83 -4 269 -16 415 -28 350 -29 743 -31 879 -6 185 34
289 85 318 155 28 66 -13 116 -122 147 -90 27 -441 16 -665 -20 -388 -61 -458
-69 -670 -69 -113 -1 -217 2 -232 7 -25 6 -26 10 -18 33 6 14 10 49 10 77 -1
222 -331 409 -647 367z m113 -225 c113 -19 216 -68 273 -130 22 -24 41 -49 41
-54 0 -5 -60 11 -133 35 -222 72 -366 89 -513 59 -88 -19 -148 -43 -210 -86
-45 -31 -47 -31 -102 -20 -82 16 -182 14 -317 -9 -212 -35 -645 -73 -850 -74
-180 -1 -193 0 -243 23 -83 38 -109 84 -80 146 36 80 162 87 533 29 228 -36
252 -38 485 -38 275 -1 360 9 605 71 252 64 359 74 511 48z m-68 -129 c52 -8
168 -38 258 -67 178 -57 174 -54 177 -131 2 -31 -2 -35 -53 -60 -97 -48 -169
-61 -315 -55 -129 5 -227 27 -318 74 -46 23 -212 134 -215 143 -4 10 93 61
150 79 37 12 137 27 193 30 17 0 72 -5 123 -13z m-398 -197 c119 -78 193 -113
295 -140 156 -41 388 -25 506 36 26 14 29 14 29 0 0 -28 -40 -95 -79 -134
-154 -154 -529 -185 -777 -66 -115 55 -174 144 -159 235 8 45 59 130 78 130 7
0 55 -28 107 -61z m894 55 c12 -5 13 -10 5 -26 -9 -17 -13 -18 -20 -7 -9 15
-12 39 -5 39 2 0 11 -3 20 -6z"/>
        </g>
    </svg>
</div>

        </>
    );
};

export default LogoUnicus;