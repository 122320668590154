import React from "react";
import Logo from "./components/Logo";
import Mains from "./components/Mains";
import Extras from "./components/Extras";
import Total from "./components/Total";
import { Provider } from "./Context";
import { mains, sides, drinks } from "./data";
//import {NavLink} from "react-router-dom";
import "./styles.css";
import LogoUnicus from "./components/LogoUnicus"
export default function App() {
  let contourLogo = '#000'
  return (
    <Provider>
      <div className="menu">
        {/* <Logo /> */}
        <div className="logo">
          <section className="logo-wrap">
          {/* <NavLink to="/"> */}
          <LogoUnicus contourLogo={contourLogo} />
          <h2>UNICUS</h2>
          <p>catering and food service</p>
          {/* </NavLink> */}
        </section>
        </div>
        
        
        <Mains meals={mains} />
        <aside className="aside">
          <Extras type="Sides" items={sides} />
          <Extras type="Drinks" items={drinks} />
        </aside>
        <Total />
      </div>
    </Provider>
  );
}
